var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isLoading ? _c('div', {
    staticClass: "d-flex justify-content-center mb-3"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1) : _c('div', [_c('el-form', {
    ref: "commentForm",
    attrs: {
      "model": _vm.form
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "comment"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "autosize": {
        minRows: 4
      },
      "disabled": !_vm.isEdit,
      "placeholder": "Please input a comment"
    },
    model: {
      value: _vm.form.comment,
      callback: function ($$v) {
        _vm.$set(_vm.form, "comment", $$v);
      },
      expression: "form.comment"
    }
  })], 1), _vm.isEnableEditing ? _c('div', {
    staticClass: "d-flex justify-content-end mt-5"
  }, [!_vm.isEdit ? _c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function ($event) {
        _vm.isEdit = true;
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "pencil-lock-outline",
      "size": 18
    }
  }), _vm._v(" Edit ")], 1) : _vm._e(), _vm.isEdit ? _c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        return _vm.reset();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "refresh",
      "size": 18
    }
  }), _vm._v(" Reset ")], 1) : _vm._e(), _vm.isEdit ? _c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": function ($event) {
        _vm.isEdit = false;
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "close-circle-outline",
      "size": 18
    }
  }), _vm._v(" Cancel ")], 1) : _vm._e(), _vm.isEdit ? _c('b-button', {
    on: {
      "click": function ($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v(" Save Comment ")]) : _vm._e()], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }